.experienceBoxContainer {
  padding: 20px;
  transition: background-color 0.6s ease-out 100ms;
}

.experienceBoxContainer:hover {
  background-color: #e5eefe;
  border-top: 0.5px solid #0641b5;
  border-bottom: 0.5px solid #0641b5;
}

.dateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.positionCompanyStyle {
  font-size: 25px;
  font-weight: 600;
  color: #00246b;
}

.descriptionStyle {
  font-size: var(--fz-sm);
  font-weight: 300;
  color: rgba(0, 0, 0, 0.77);
}

.dateLocationStyle {
  color: rgba(128, 128, 128, 0.71);
  font-size: 20px;
  font-weight: 300;
  text-align: center;
}

.showMobile {
  display: none;
}

@media (max-width: 1199.98px) {
  .positionCompanyStyle {
    font-size: 20px;
  }

  .dateLocationStyle {
    font-size: 15px;
  }
}

@media (max-width: 600px) {
  .positionCompanyStyle {
    font-size: 16px;
  }

  .dateContainer {
    display: flex;
    flex-direction: row;
  }

  .dateLocationStyle {
    font-size: 13px;
  }

  .showMobile {
    display: block;
  }

  .hideMobile {
    display: none;
  }
}
