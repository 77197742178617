.educationContainer {
  flex-direction: column;
  display: flex;
  background-color: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.titleStyle {
  color: #00246b;
  font-weight: 600;
  font-size: 25px;
}

.bodyStyle {
  color: rgba(0, 0, 0, 0.77);
  font-size: var(--fz-sm);
  font-weight: 300;
}

.educationBoxContainer {
  display: flex;
  padding: 20px;
  transition: background-color 0.6s ease-out 100ms;
}

.educationBoxContainer:hover {
  background-color: #e5eefe;
  border-top: 0.5px solid #0641b5;
  border-bottom: 0.5px solid #0641b5;
}

.schoolContainer {
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.descriptionContainer {
  display: flex;
  flex-direction: column;
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
}

.headerShowHide {
  display: none;
  color: #8ab6f9;
  font-weight: 700;
  font-size: 20px;
}

@media (max-width: 1200px) {
  .educationContainer {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }

  .titleStyle {
    font-size: 16px;
  }

  .bodyStyle {
    font-size: 13px;
  }

  .headerShowHide {
    display: block;
  }
}
