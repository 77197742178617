.aboutContainer {
  padding-top: 6rem;
  padding-bottom: 6rem;
  background-color: white;
  display: flex;
  flex-direction: column;
}

.aboutMeContainer {
  display: flex;
  color: #00246b;
  flex-direction: column;
  justify-content: center;
}

.name {
  margin: 0;
  font-size: 70px;
  font-weight: 800;
}

.aboutMeText {
  font-size: 26px;
  font-weight: 250;
  color: rgba(0, 0, 0, 0.77);
}

.pfpShowHide {
  display: none;
}

.socialIconShowHide {
  display: none;
}

@media (max-width: 1200px) {
  .name {
    font-size: 35px;
  }

  .aboutMeText {
    font-size: 15px;
  }

  .pfpShowHide {
    display: block;
  }

  .socialIconShowHide {
    display: block;
  }

  .aboutContainer {
    padding-top: 1rem;
    padding-bottom: 0rem;
  }
}

@media (max-width: 600px) {
  .name {
    font-weight: 1000;
  }
}
