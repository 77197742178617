.projectContainer {
  flex-direction: column;
  display: flex;
  background-color: white;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.headerShowHide {
  display: none;
  color: #8ab6f9;
  font-weight: 700;
  font-size: 20px;
}

@media (max-width: 1200px) {
  .projectContainer {
    padding-top: 2rem;
    padding-bottom: 0rem;
  }

  .headerShowHide {
    display: block;
  }
}
