.sideBarBox {
  width: 262px;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: fixed;
  padding-top: 6rem;
}

.imgStyle {
  width: 100%;
  border-radius: 10px;
  margin-top: 27px;
}
.socialIcons {
  display: "flex";
  width: 100%;
  /* margin-top: 50%; */
}

@media screen and (max-width: 1200px) {
  .sideBarBox {
    display: block;
  }
}
