.skillWidget {
  height: 30px;
  display: flex;
  align-items: center;
  border-radius: 50px;
  padding-left: 12px;
  padding-right: 12px;
  margin-right: 10px;
  background-color: #cadcfc;
  color: #00246b;
  font-size: 13px;
  font-weight: 700;
}

@media (max-width: 1200px) {
  .skillWidget {
    height: 25px;
    font-size: 10px;
  }
}
