.sideBarText {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.sideBarText ul {
  padding: 0;
  margin: 13px;
  font-size: 20px;
  font-weight: 400;
  cursor: pointer;
}

#about {
  position: relative;
  text-decoration: none;
  color: #00246b;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
  font-weight: 400;
  margin: 13px 0;
}

#about::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #8ab6f9;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  transform: scaleX(0);
}

#about:hover {
  color: #8ab6f9;
  font-weight: 600;
  transform: scale(1.1);
}

#about:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#projects {
  position: relative;
  text-decoration: none;
  color: #00246b;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
  font-weight: 400;
  margin: 13px 0;
}

#projects::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #8ab6f9;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  transform: scaleX(0);
}

#projects:hover {
  color: #8ab6f9;
  font-weight: 600;
  transform: scale(1.1);
}

#projects:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#education {
  position: relative;
  text-decoration: none;
  color: #00246b;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
  font-weight: 400;
  margin: 13px 0;
}

#education::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #8ab6f9;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  transform: scaleX(0);
}

#education:hover {
  color: #8ab6f9;
  font-weight: 600;
  transform: scale(1.1);
}

#education:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#education:hover {
  color: #8ab6f9;
  font-weight: 600;
}

#experience {
  position: relative;
  text-decoration: none;
  color: #00246b;
  transition: all 0.2s ease-in-out;
  font-size: 20px;
  font-weight: 400;
  margin: 13px 0;
}

#experience::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 3px;
  bottom: 0;
  left: 0;
  background-color: #8ab6f9;
  transform-origin: bottom right;
  transition: transform 0.5s ease-out;
  transform: scaleX(0);
}

#experience:hover {
  color: #8ab6f9;
  font-weight: 600;
  transform: scale(1.1);
}

#experience:hover::after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

#experience:hover {
  color: #8ab6f9;
  font-weight: 600;
}

@media screen and (max-width: 768px) {
  .sideBarText {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }
}

@media screen and (max-width: 400px) {
  .sideBarText {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
