.container {
  font-family: "Inter", sans-serif;
}

.showHideSideBar {
  display: block;
}

@media screen and (max-width: 1200px) {
  .showHideSideBar {
    display: none;
  }
}
