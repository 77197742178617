.projectsBoxContainer {
  display: flex;
  padding: 20px;
  transition: background-color 0.6s ease-out 100ms;
}

.projectsBoxContainer:hover {
  background-color: #e5eefe;
  border-top: 0.5px solid #0641b5;
  border-bottom: 0.5px solid #0641b5;
}

.imgContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.descriptionStyle {
  font-size: 17px;
  font-weight: 300;
  word-wrap: break-word;
}

.projectTitleStyle {
  font-size: 25px;
  font-weight: 600;
  color: #00246b;
}

.skillsContainer {
  display: flex;
  flex-wrap: wrap;
  row-gap: 10px;
  margin-top: 10px;
}

@media (max-width: 600px) {
  .projectTitleStyle {
    font-size: 16px;
  }

  .descriptionStyle {
    font-size: 13px;
  }
}
